import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ClearIcon from '@material-ui/icons/Clear';
import QrReader from 'react-qr-reader'

var QRCode = require('qrcode.react');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));
function App() {

  const classes = useStyles();


  let cachedParties = localStorage.getItem("parties");
  if (cachedParties === null) {
    localStorage.setItem("parties", JSON.stringify([]));
  }
  const [partys, addParty] = useState(JSON.parse(localStorage.getItem("parties")));

  const [shouldScan, enableScan] = useState(false);
  console.log(" start partys ", partys, localStorage.getItem("parties"))

  const updateParties = (p) => {
    addParty(p)
    localStorage.setItem("parties", JSON.stringify(p))
  }

  let _addParty = () => {
    console.log("Party Promt");
    let partyName = prompt("Party Name", "A");
    updateParties([...partys, {
      "name": partyName,
      "spendings": []
    }])
  }

  const addSpending = (partyName) => {
    console.log("add spending for ", partyName)
    let spendingName = prompt("Spending Name");
    let spending = parseFloat(prompt("Add Spending (number)").replace(",", "."));
    if (isNaN(spending)) {
      console.log(spending, " is not a number");
      return
    }
    console.log("add spending for ", partyName, spending, " as ", spendingName);
    const patchedParties = partys.map(party => {
      if (party.name === partyName) {
        party.spendings.push({ "name": spendingName, "cost": spending })
        return { ...party }
      }
      return party
    })
    updateParties(patchedParties)
    console.log("Patched ", patchedParties)
  }

  const sum = (array) => array.reduce((pv, cv) => pv + cv, 0)

  const spendingsPerParty = partys.map(party => { return { "party": party.name, "sum": sum(party.spendings.map(spending => spending.cost)) } })
  const sumSpendings = sum(spendingsPerParty.map(_sum => _sum.sum));
  const costPerParty = isNaN(sumSpendings / partys.length) ? 0 : sumSpendings / partys.length;
  const balancing = spendingsPerParty
    .map(party => { return { ...party, "balacing": party.sum - costPerParty } })
    .map(party => { return { ...party, "getsMoneyBack": party.balacing > 0 } })
  console.log("spendingsPerParty", spendingsPerParty,
    "sumSpendings", sumSpendings,
    "costPerParty", costPerParty,
    "balancing", balancing)

  

  return (<div className={classes.root}>

    <CssBaseline />
    <Container maxWidth="md">
      <h1>WAKRINO</h1><h3>WAs KRiegt Ihr NOch? - Der Urlaubskostenplaner für Freunde</h3>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card >
            <CardContent>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Split the costs
        </Typography>
              <Typography variant="h5" component="h2">
                Total costs: {sumSpendings}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                Parties: {partys.length}
              </Typography>
              <Typography variant="body2" component="p">
                Costs per Party: <sup>{sumSpendings}</sup>&frasl;<sub>{partys.length}</sub> = {!!costPerParty ? costPerParty.toFixed(2) : 0}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Fab onClick={() => { updateParties([]) }} color="default" aria-label="add">
                  <ClearIcon />
                </Fab>

                <Fab onClick={_addParty} color="secondary" aria-label="add" className={classes.fab}>
                  <AddIcon />
                </Fab>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>



      <Grid container spacing={3}>
        {partys.map(party => {
          const balacing = balancing.filter(_ => _.party === party.name)[0]
          let paysOrGets = balacing.getsMoneyBack ? `Gets ${balacing.balacing.toFixed(2)}` : `Must pay ${Math.abs(balacing.balacing).toFixed(2)}`;

          return (<Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <h5>{party.name} [{paysOrGets}]</h5>
              <List>
                {party.spendings.map(s => <ListItem><ListItemText>{s.cost.toFixed(2)} :: <i>{s.name}</i></ListItemText><ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction></ListItem>)}
                <ListItem><ListItemText><div style={{ borderTop: '1px solid' }}>{sum(party.spendings.map(_ => _.cost)).toFixed(2)}</div></ListItemText></ListItem>

              </List>
              <Fab onClick={() => { addSpending(party.name) }} color="primary" aria-label="add">
                <AddIcon />
              </Fab>
            </Paper>
          </Grid>)
        })}
        <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
            <h5>Share</h5>
            <QRCode value={JSON.stringify(partys)} />
            
    <div>
      <h1 onClick={() => {enableScan(true)}}>Scan the code!</h1>
      {shouldScan && 
      <div>
        <QrReader
          delay={300}
          onError={(error) => {console.log("error", error)}}
          onScan={(data) => {
              if(data === null) {
                return;
              }
              try {
                let newData = JSON.parse(data);
                enableScan(false);
                console.log("Data ", data)
                updateParties(newData);
              } catch (e) {
                console.log("error while image parsing")
              }
          }}
          style={{ width: '100%' }}
        />
        <p>Hi</p>
      </div>
}
    </div>

            </Paper>
            </Grid>
      </Grid>

    </Container>


  </div>
  );
}

export default App;
